.styled-table {
  border-collapse: collapse;
  width: 100%;
  @apply divide-y divide-gray-200;
}

.styled-table thead {
  background-color: #f2f2f2;
}

.styled-table th,
.styled-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.styled-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.styled-table tbody tr:hover {
  background-color: #ddd;
}

.edit-but,
.delet-but {
  display: flex;
  gap: 2px;
  cursor: pointer;
  font-size: 1rem;
}

.delet-but {
  color: red;
}

.edit-but {
  color: gray;
}

.add_head {
  padding-top: 2rem;
  /* cursor: pointer; */
}

.add_head_but {
  padding: 1.5rem;
  font-size: 2rem;
  color: #33333390;
  border-radius: 0.4rem;
  cursor: pointer;
}
